var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { IFrameWindow } from './IFrameHelper';
import { AuthorizationError, AuthorizationRequest, AuthorizationRequestHandler, AuthorizationResponse, BasicQueryStringUtils, DefaultCrypto, LocalStorageBackend, } from '@openid/appauth';
var authorizationRequestKey = function (handle) {
    return "".concat(handle, "_appauth_authorization_request");
};
var authorizationServiceConfigurationKey = function (handle) {
    return "".concat(handle, "_appauth_authorization_service_configuration");
};
var AUTHORIZATION_REQUEST_HANDLE_KEY = 'appauth_current_authorization_request';
var IFrameRequestHandler = (function (_super) {
    __extends(IFrameRequestHandler, _super);
    function IFrameRequestHandler(storageBackend, utils, crypto) {
        if (storageBackend === void 0) { storageBackend = new LocalStorageBackend(); }
        if (utils === void 0) { utils = new BasicQueryStringUtils(); }
        if (crypto === void 0) { crypto = new DefaultCrypto(); }
        var _this = _super.call(this, utils, crypto) || this;
        _this.storageBackend = storageBackend;
        return _this;
    }
    IFrameRequestHandler.prototype.setParams = function (params) {
        this._params = params;
    };
    IFrameRequestHandler.prototype.performAuthorizationRequest = function (configuration, request) {
        var _this = this;
        this._IFrame = new IFrameWindow(this._params);
        var handle = this.crypto.generateRandom(10);
        var persisted = Promise.all([
            this.storageBackend.setItem(AUTHORIZATION_REQUEST_HANDLE_KEY, handle),
            request
                .toJson()
                .then(function (result) {
                return _this.storageBackend.setItem(authorizationRequestKey(handle), JSON.stringify(result));
            }),
            this.storageBackend.setItem(authorizationServiceConfigurationKey(handle), JSON.stringify(configuration.toJson())),
        ]).catch(function (e) {
            if (_this._IFrame) {
                _this._IFrame.abort();
            }
            return Promise.reject(new Error("Failed to store OIDC request in local-storage: ".concat(e)));
        });
        return persisted
            .then(function () {
            if (_this._IFrame) {
                _this._IFrame.navigate({
                    url: _this.buildRequestUrl(configuration, request),
                    id: request.state,
                });
                return _this._IFrame.promise
                    .then(function (authorizationResponse) {
                    return Promise.all([
                        _this.storageBackend.removeItem(AUTHORIZATION_REQUEST_HANDLE_KEY),
                        _this.storageBackend.removeItem(authorizationRequestKey(handle)),
                        _this.storageBackend.removeItem(authorizationServiceConfigurationKey(handle)),
                    ]).then(function () {
                        return {
                            request: request,
                            response: authorizationResponse,
                            error: null,
                        };
                    });
                })
                    .catch(function (e) {
                    if (e.error != undefined) {
                        return Promise.reject(e);
                    }
                    else {
                        return Promise.reject(new Error("Invalid response from completeAuthorizationRequest: ".concat(e.toString())));
                    }
                });
            }
            else {
                return Promise.all([
                    _this.storageBackend.removeItem(AUTHORIZATION_REQUEST_HANDLE_KEY),
                    _this.storageBackend.removeItem(authorizationRequestKey(handle)),
                    _this.storageBackend.removeItem(authorizationServiceConfigurationKey(handle)),
                ]).then(function () {
                    return Promise.reject(new Error('Failed to create / get IFrame'));
                });
            }
        })
            .catch(function (e) {
            if (_this._IFrame) {
                _this._IFrame.abort();
            }
            return Promise.reject(e);
        });
    };
    IFrameRequestHandler.prototype.completeAuthorizationRequest = function () {
        var _this = this;
        return this.storageBackend
            .getItem(AUTHORIZATION_REQUEST_HANDLE_KEY)
            .then(function (handle) {
            if (handle) {
                return _this.storageBackend
                    .getItem(authorizationRequestKey(handle))
                    .then(function (result) { return JSON.parse(result); })
                    .then(function (json) { return new AuthorizationRequest(json); })
                    .then(function (request) {
                    var queryParams = new URLSearchParams(window.location.hash.replace('#', '?'));
                    var state = queryParams.get('state') || undefined;
                    var code = queryParams.get('code') || undefined;
                    var error = queryParams.get('error') || undefined;
                    if (state && (code || error)) {
                        var shouldNotify = state === request.state;
                        var authorizationResponse = null;
                        var authorizationError = null;
                        if (shouldNotify) {
                            if (error) {
                                var errorUri = queryParams.get('error_uri') || undefined;
                                var errorDescription = queryParams.get('error_description') || undefined;
                                authorizationError = new AuthorizationError({
                                    error: error,
                                    error_description: errorDescription,
                                    error_uri: errorUri,
                                    state: state,
                                });
                            }
                            else if (code) {
                                authorizationResponse = new AuthorizationResponse({
                                    code: code,
                                    state: state,
                                });
                            }
                            return {
                                request: request,
                                response: authorizationResponse,
                                error: authorizationError,
                            };
                        }
                    }
                    return null;
                });
            }
            else {
                return null;
            }
        });
    };
    return IFrameRequestHandler;
}(AuthorizationRequestHandler));
export { IFrameRequestHandler };
