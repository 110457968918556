var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { PopupWindow } from './helper';
import { IFrameWindow } from './helper/IFrameHelper';
import { IFrameRequestHandler } from './helper/IFrameRequestHandler';
import { PopupRequestHandler } from './helper/PopupRequestHandler';
import { AppAuthError, AuthorizationNotifier, AuthorizationRequest, AuthorizationServiceConfiguration, BaseTokenRequestHandler, DefaultCrypto, FetchRequestor, GRANT_TYPE_AUTHORIZATION_CODE, GRANT_TYPE_REFRESH_TOKEN, LocalStorageBackend, nowInSeconds, RedirectRequestHandler, TokenError, TokenRequest, TokenResponse, } from '@openid/appauth';
export var EventType;
(function (EventType) {
    EventType[EventType["EXPIRED"] = 0] = "EXPIRED";
    EventType[EventType["RENEWED"] = 1] = "RENEWED";
    EventType[EventType["REFRESH_ERROR"] = 2] = "REFRESH_ERROR";
})(EventType || (EventType = {}));
var TIMER_DURATION = 5 * 1000;
var AuthAdapter = (function () {
    function AuthAdapter(settings) {
        var _this = this;
        this._crypto = new DefaultCrypto();
        this._localStorage = new LocalStorageBackend();
        this._handlers = [];
        this._notifier = new AuthorizationNotifier();
        this._popupNotifier = new AuthorizationNotifier();
        this._iframeNotifier = new AuthorizationNotifier();
        this._authorizationHandler = new RedirectRequestHandler(this._localStorage);
        this._popupAuthorizationHandler = new PopupRequestHandler(this._localStorage);
        this._iframeAuthorizationHandler = new IFrameRequestHandler(this._localStorage);
        var fetchRequestor = new FetchRequestor();
        this._tokenHandler = new ExtendedTokenRequestHandler(fetchRequestor);
        this._authorizationHandler.setAuthorizationNotifier(this._notifier);
        this._popupAuthorizationHandler.setAuthorizationNotifier(this._popupNotifier);
        this._iframeAuthorizationHandler.setAuthorizationNotifier(this._iframeNotifier);
        this._timerHandle = setInterval(function () { return _this.checkExpire(); }, TIMER_DURATION);
        this._settings = settings;
        this._notifier.setAuthorizationListener(function (request, response, error) {
            if (error !== null) {
                console.error('Auth error:', error);
            }
            if (response) {
                var codeVerifier = void 0;
                if (request.internal && request.internal.code_verifier) {
                    codeVerifier = request.internal.code_verifier;
                }
                _this.finishAuthorization(response.code, codeVerifier)
                    .then(function () { return _this.refreshTokens(); })
                    .then(function () {
                    _this._handlers.forEach(function (fn) {
                        fn(EventType.RENEWED, _this._accessTokenResponse);
                    });
                });
            }
        });
        this._popupNotifier.setAuthorizationListener(function (request, response, error) {
            PopupWindow.notifyOpener(request, response, error);
        });
        this._iframeNotifier.setAuthorizationListener(function (request, response, error) {
            IFrameWindow.notifyOpener(request, response, error);
        });
    }
    AuthAdapter.prototype.checkExpire = function () {
        var _this = this;
        if (this._accessTokenResponse) {
            if (!this._accessTokenResponse.isValid(-60)) {
                this.refreshTokens()
                    .then(function () {
                    _this._handlers.forEach(function (fn) {
                        fn(EventType.RENEWED, _this._accessTokenResponse);
                    });
                })
                    .catch(function (error) {
                    _this._handlers.forEach(function (fn) {
                        fn(EventType.REFRESH_ERROR, error);
                    });
                });
            }
            if (!this._accessTokenResponse.isRefreshValid()) {
                this.refreshTokens()
                    .then(function () {
                    _this._handlers.forEach(function (fn) {
                        fn(EventType.RENEWED, _this._accessTokenResponse);
                    });
                })
                    .catch(function (error) {
                    _this._handlers.forEach(function (fn) {
                        fn(EventType.REFRESH_ERROR, error);
                    });
                });
            }
        }
    };
    AuthAdapter.prototype.addHandler = function (fn) {
        this._handlers.push(fn);
    };
    AuthAdapter.prototype.removeHandler = function (fnToRemove) {
        this._handlers = this._handlers.filter(function (fn) {
            if (fn != fnToRemove)
                return fn;
        });
    };
    AuthAdapter.prototype.fetchServiceConfiguration = function () {
        var _this = this;
        var fetcher = new FetchRequestor();
        return AuthorizationServiceConfiguration.fetchFromIssuer(this.authority, fetcher).then(function (response) {
            _this._configuration = response;
        });
    };
    AuthAdapter.prototype.maybeFetchServiceConfiguration = function () {
        if (this._configuration === undefined) {
            return this.fetchServiceConfiguration();
        }
        else {
            return Promise.resolve();
        }
    };
    Object.defineProperty(AuthAdapter.prototype, "clientId", {
        get: function () {
            return this._settings.clientId;
        },
        enumerable: false,
        configurable: true
    });
    Object.defineProperty(AuthAdapter.prototype, "authority", {
        get: function () {
            return this._settings.authority;
        },
        enumerable: false,
        configurable: true
    });
    AuthAdapter.prototype.checkExpirationValues = function (response) {
        return (Number(response.expiresIn) >= 0 && Number(response.refreshExpiresIn) >= 0);
    };
    AuthAdapter.prototype.finishAuthorization = function (code, codeVerifier, popup) {
        var _this = this;
        if (popup === void 0) { popup = false; }
        if (!this._configuration) {
            return Promise.reject(new Error('Unknown service configuration'));
        }
        var extras = {};
        if (codeVerifier) {
            extras.code_verifier = codeVerifier;
        }
        var request = new TokenRequest({
            client_id: this.clientId,
            redirect_uri: popup
                ? this._settings.popupRedirectUri
                : this._settings.redirectUri,
            grant_type: GRANT_TYPE_AUTHORIZATION_CODE,
            code: code,
            refresh_token: undefined,
            extras: extras,
        });
        return this._tokenHandler
            .performTokenRequest(this._configuration, request)
            .then(function (response) {
            if (_this.checkExpirationValues(response)) {
                _this._refreshToken = response.refreshToken;
                _this._accessTokenResponse = response;
                return response.accessToken;
            }
            return Promise.reject(new Error('error_oidc_configuration'));
        })
            .catch(function () {
            clearTimeout(_this._timerHandle);
            return Promise.reject(new Error('error_oidc_configuration'));
        });
    };
    AuthAdapter.prototype.refreshTokens = function () {
        var _this = this;
        if (!this._configuration) {
            return Promise.reject(new Error('Unknown service configuration'));
        }
        if (!this._refreshToken) {
            return Promise.reject(new Error('Missing refreshToken.'));
        }
        if (this._accessTokenResponse && this._accessTokenResponse.isValid()) {
            return Promise.resolve(this._accessTokenResponse.accessToken);
        }
        var request = new TokenRequest({
            client_id: this.clientId,
            redirect_uri: this._settings.redirectUri,
            grant_type: GRANT_TYPE_REFRESH_TOKEN,
            code: undefined,
            refresh_token: this._refreshToken,
            extras: undefined,
        });
        return this._tokenHandler
            .performTokenRequest(this._configuration, request)
            .then(function (response) {
            if (_this.checkExpirationValues(response)) {
                _this._accessTokenResponse = response;
                _this._refreshToken = response.refreshToken;
                return response.accessToken;
            }
            return Promise.reject(new Error('error_oidc_configuration'));
        })
            .catch(function () {
            clearInterval(_this._timerHandle);
            return Promise.reject(new Error('error_oidc_configuration'));
        });
    };
    AuthAdapter.prototype.completeAuthorizationRequestIfPossible = function () {
        var _this = this;
        return this._localStorage
            .getItem('appauth_current_authorization_request')
            .then(function (handle) {
            if (handle) {
                return _this._localStorage
                    .getItem("".concat(handle, "_appauth_authorization_request"))
                    .then(function (result) { return JSON.parse(result); })
                    .then(function (json) { return new AuthorizationRequest(json); })
                    .then(function (request) {
                    if (request.internal && request.internal.request_type) {
                        switch (request.internal.request_type) {
                            default:
                            case 'redirect':
                                return _this._authorizationHandler.completeAuthorizationRequestIfPossible();
                            case 'popup':
                                return _this._popupAuthorizationHandler.completeAuthorizationRequestIfPossible();
                            case 'iframe':
                                return _this._iframeAuthorizationHandler.completeAuthorizationRequestIfPossible();
                        }
                    }
                    else {
                        return _this._authorizationHandler.completeAuthorizationRequestIfPossible();
                    }
                });
            }
        });
    };
    AuthAdapter.prototype.signInRedirect = function (args) {
        if (!args) {
            args = { extras: { response_mode: 'fragment' } };
        }
        if (args && !args.extras) {
            args.extras = { response_mode: 'fragment' };
        }
        var internal = {
            request_type: 'redirect',
        };
        var request = new AuthorizationRequest({
            client_id: this.clientId,
            redirect_uri: this._settings.redirectUri,
            scope: this._settings.scope,
            response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
            state: undefined,
            extras: args.extras,
            internal: internal,
        });
        request.setupCodeVerifier();
        if (this._configuration) {
            this._authorizationHandler.performAuthorizationRequest(this._configuration, request);
            return Promise.resolve();
        }
        else {
            return Promise.reject(new Error('Unknown service configuration'));
        }
    };
    AuthAdapter.prototype.signInSilent = function (args) {
        if (this._refreshToken) {
            return this.refreshTokens();
        }
        else {
            return this.signInSilentIFrame(args);
        }
    };
    AuthAdapter.prototype.signInPopup = function (args) {
        var _this = this;
        if (!args) {
            args = { extras: { response_mode: 'fragment' } };
        }
        if (!args.extras) {
            args.extras = { response_mode: 'fragment' };
        }
        var internal = {
            request_type: 'popup',
        };
        var request = new AuthorizationRequest({
            client_id: this.clientId,
            redirect_uri: this._settings.popupRedirectUri || this._settings.redirectUri,
            scope: this._settings.scope,
            response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
            state: undefined,
            extras: args && args.extras ? args.extras : {},
            internal: internal,
        });
        request.setupCodeVerifier();
        if (this._configuration) {
            return this._popupAuthorizationHandler
                .performAuthorizationRequest(this._configuration, request)
                .then(function (_a) {
                var request = _a.request, response = _a.response;
                if (response !== null) {
                    var codeVerifier = void 0;
                    if (request.internal && request.internal.code_verifier) {
                        codeVerifier = request.internal.code_verifier;
                    }
                    return _this.finishAuthorization(response.code, codeVerifier, true)
                        .then(function () { return _this.refreshTokens(); })
                        .then(function () {
                        _this._handlers.forEach(function (fn) {
                            fn(EventType.RENEWED, _this._accessTokenResponse);
                        });
                    });
                }
            });
        }
        else {
            return Promise.reject(new Error('Unknown service configuration'));
        }
    };
    AuthAdapter.prototype.signOut = function (args) {
        var _a, _b;
        if (((_a = this._configuration) === null || _a === void 0 ? void 0 : _a.endSessionEndpoint) !== undefined) {
            var logoutUrl = new URL((_b = this._configuration) === null || _b === void 0 ? void 0 : _b.endSessionEndpoint);
            logoutUrl.searchParams.append('client_id', this.clientId);
            if (this._accessTokenResponse && this._accessTokenResponse.idToken) {
                logoutUrl.searchParams.append('id_token_hint', this._accessTokenResponse.idToken);
            }
            logoutUrl.searchParams.append('post_logout_redirect_uri', (args && args.redirectUri) || this._settings.signOutRedirectUri);
            this._accessTokenResponse = undefined;
            this._refreshToken = undefined;
            window.location.replace(logoutUrl);
        }
    };
    AuthAdapter.prototype.signInSilentIFrame = function (args) {
        var _this = this;
        if (args === void 0) { args = {}; }
        var url = args.redirect_uri ||
            this._settings.silentRedirectUri ||
            this._settings.redirectUri;
        if (!args) {
            args = { extras: { response_mode: 'fragment' } };
        }
        if (!args.extras) {
            args.extras = { response_mode: 'fragment' };
        }
        args.extras.prompt = args.extras.prompt || 'none';
        var internal = {
            request_type: 'iframe',
        };
        var request = new AuthorizationRequest({
            client_id: this.clientId,
            redirect_uri: url,
            scope: this._settings.scope,
            response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
            state: undefined,
            extras: args && args.extras ? args.extras : {},
            internal: internal,
        });
        request.setupCodeVerifier();
        if (this._configuration) {
            return this._iframeAuthorizationHandler
                .performAuthorizationRequest(this._configuration, request)
                .then(function (_a) {
                var request = _a.request, response = _a.response, error = _a.error;
                if (response !== null) {
                    var codeVerifier = void 0;
                    if (request.internal && request.internal.code_verifier) {
                        codeVerifier = request.internal.code_verifier;
                    }
                    return _this.finishAuthorization(response.code, codeVerifier, true)
                        .then(function () { return _this.refreshTokens(); })
                        .then(function () {
                        _this._handlers.forEach(function (fn) {
                            fn(EventType.RENEWED, _this._accessTokenResponse);
                        });
                    });
                }
                else if (error !== null) {
                    if (error.error === 'login_required') {
                        return Promise.reject(new Error('Login Required'));
                    }
                }
            });
        }
        else {
            return Promise.reject(new Error('Unknown service configuration'));
        }
    };
    return AuthAdapter;
}());
export { AuthAdapter };
var ExtendedOidcTokenResponse = (function (_super) {
    __extends(ExtendedOidcTokenResponse, _super);
    function ExtendedOidcTokenResponse(response) {
        var _this = _super.call(this, response) || this;
        if (response.refresh_expires_in) {
            _this.refreshExpiresIn = parseInt(response.refresh_expires_in, 10);
        }
        if (response.session_state) {
            _this.sessionState = response.session_state;
        }
        if (response['not-before-policy']) {
            _this.notBeforePolicy = response['not-before-policy'];
        }
        return _this;
    }
    ExtendedOidcTokenResponse.prototype.toJson = function () {
        var _a, _b;
        return {
            access_token: this.accessToken,
            id_token: this.idToken,
            refresh_token: this.refreshToken,
            refresh_expires_in: (_a = this.refreshExpiresIn) === null || _a === void 0 ? void 0 : _a.toString(),
            scope: this.scope,
            token_type: this.tokenType,
            issued_at: this.issuedAt,
            expires_in: (_b = this.expiresIn) === null || _b === void 0 ? void 0 : _b.toString(),
            session_state: this.sessionState,
            'not-before-policy': this.notBeforePolicy,
        };
    };
    ExtendedOidcTokenResponse.prototype.toReduxState = function () {
        if (this.expiresIn === null ||
            this.idToken === null ||
            this.refreshToken === null ||
            this.refreshExpiresIn === null ||
            this.sessionState === null ||
            this.scope === null) {
            return Promise.reject('Missing extended OIDC (expiresIn, idToken, refreshExpiresIn or sessionState');
        }
        return Promise.resolve({
            access_token: this.accessToken,
            access_token_expire: ((this.issuedAt + this.expiresIn) *
                1000),
            id_token: this.idToken,
            refresh_token: this.refreshToken,
            refresh_token_expire: (this.issuedAt +
                this.expiresIn * 1000),
            scope: this.scope,
            session_id: this.sessionState,
        });
    };
    ExtendedOidcTokenResponse.prototype.isRefreshValid = function (buffer) {
        if (buffer === void 0) { buffer = 10 * 60 * -1; }
        if (this.refreshExpiresIn) {
            var now = nowInSeconds();
            return now < this.issuedAt + this.refreshExpiresIn + buffer;
        }
        else {
            return true;
        }
    };
    return ExtendedOidcTokenResponse;
}(TokenResponse));
export { ExtendedOidcTokenResponse };
var ExtendedTokenRequestHandler = (function (_super) {
    __extends(ExtendedTokenRequestHandler, _super);
    function ExtendedTokenRequestHandler() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    ExtendedTokenRequestHandler.prototype.isExtendedTokenResponse = function (response) {
        return response.error === undefined;
    };
    ExtendedTokenRequestHandler.prototype.performTokenRequest = function (configuration, request) {
        var _this = this;
        var cleaned_request = request.toStringMap();
        if (request.grantType === GRANT_TYPE_REFRESH_TOKEN) {
            delete cleaned_request['redirect_uri'];
        }
        var tokenResponse = this.requestor.xhr({
            url: configuration.tokenEndpoint,
            method: 'POST',
            dataType: 'json',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            data: this.utils.stringify(request.toStringMap()),
        });
        return tokenResponse.then(function (response) {
            if (_this.isExtendedTokenResponse(response)) {
                return new ExtendedOidcTokenResponse(response);
            }
            else {
                return Promise.reject(new AppAuthError(response.error, new TokenError(response)));
            }
        });
    };
    return ExtendedTokenRequestHandler;
}(BaseTokenRequestHandler));
