// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2

enum ChatScope {
  Global = 'global',
  Private = 'private',
  Group = 'group',
}

export default ChatScope;
