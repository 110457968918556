// SPDX-FileCopyrightText: OpenTalk GmbH <mail@opentalk.eu>
//
// SPDX-License-Identifier: EUPL-1.2

enum SortOption {
  NameASC = 'nameASC',
  NameDESC = 'nameDESC',
  FirstJoin = 'firstJoin',
  LastJoin = 'lastJoin',
  LastActive = 'lastActive',
  RaisedHandFirst = 'raisedHandFirst',
}

export default SortOption;
