import { DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS, } from '.';
var CheckForPopupClosedInterval = 500;
var DefaultPopupFeatures = 'location=no,toolbar=no,width=500,height=500,left=100,top=100;';
var DefaultPopupTarget = '_blank';
var PopupWindow = (function () {
    function PopupWindow(params) {
        var _this = this;
        this._resolve = function () { };
        this._reject = function () { };
        this._promise = new Promise(function (resolve, reject) {
            _this._resolve = resolve;
            _this._reject = reject;
        });
        var target = params
            ? params.popupWindowTarget || DefaultPopupTarget
            : DefaultPopupTarget;
        var features = params
            ? params.popupWindowFeatures || DefaultPopupFeatures
            : DefaultPopupFeatures;
        this._popup = window.open('', target, features);
    }
    Object.defineProperty(PopupWindow.prototype, "promise", {
        get: function () {
            return this._promise;
        },
        enumerable: false,
        configurable: true
    });
    PopupWindow.prototype.navigate = function (params) {
        var _this = this;
        if (!this._popup) {
            this._reject(new Error('PopupWindow.navigate: Error opening popup window'));
        }
        else if (!params || !params.url) {
            this._reject(new Error('PopupWindow.navigate: no url provided'));
            this._reject(new Error('No url provided'));
        }
        else {
            this._checkForPopupClosedTimer = window.setInterval(function () {
                if (!_this._popup || _this._popup.closed) {
                    _this.cleanup();
                    clearTimeout(timeoutId_1);
                    _this._reject(new Error('Popup window closed'));
                }
            }, params.popupClosedTimerInMilliseconds || CheckForPopupClosedInterval);
            var timeoutId_1 = setTimeout(function () {
                clearInterval(_this._checkForPopupClosedTimer);
                _this.cleanup();
                _this._reject(new Error('Popup Timeout'));
            }, (params.timeoutInSeconds || DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS) * 1000);
            this._popupEventListener = function (e) {
                if (!e.data || e.data.type !== 'authorization_response') {
                    return;
                }
                _this.cleanup();
                if (e.data.response) {
                    return _this._resolve(e.data.response);
                }
                if (e.data.error) {
                    return _this._reject(e.data.error);
                }
                return _this._reject(new Error('OIDC: Both response and error where empty'));
            };
            window.addEventListener('message', this._popupEventListener);
            this._popup.focus();
            this._popup.window.location.replace(params.url);
        }
        return this.promise;
    };
    PopupWindow.prototype.cleanup = function () {
        if (this._checkForPopupClosedTimer !== null) {
            window.clearInterval(this._checkForPopupClosedTimer);
        }
        this._checkForPopupClosedTimer = undefined;
        if (this._popupEventListener) {
            window.removeEventListener('message', this._popupEventListener, false);
        }
        if (this._popup) {
            this._popup.close();
        }
        this._popup = null;
    };
    PopupWindow.prototype.abort = function () {
        if (this._checkForPopupClosedTimer !== null) {
            window.clearInterval(this._checkForPopupClosedTimer);
        }
        this._checkForPopupClosedTimer = undefined;
        if (this._popupEventListener) {
            window.removeEventListener('message', this._popupEventListener, false);
        }
        if (this._popup) {
            this._popup.close();
        }
        this._popup = null;
    };
    PopupWindow.notifyOpener = function (request, response, error) {
        if (window.opener && window != window.opener) {
            window.opener.postMessage({
                type: 'authorization_response',
                request: request,
                response: response,
                error: error,
            }, window.location.origin);
        }
    };
    return PopupWindow;
}());
export { PopupWindow };
