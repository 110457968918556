var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { AuthAdapter, EventType, } from './AuthAdapter';
import { expired, loaded, loading, logged_out, selectIsAuthed, selectIsLoading, token_updated, auth_error, } from './AuthSlice';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Provider, useDispatch, useSelector } from 'react-redux';
export var EventTypeError;
(function (EventTypeError) {
    EventTypeError["SESSION_EXPIRED"] = "session expired";
})(EventTypeError || (EventTypeError = {}));
export var hasCodeInUrl = function (location) {
    var searchParams = new URLSearchParams(location.search);
    var hashParams = new URLSearchParams(location.hash.replace('#', '?'));
    return Boolean(searchParams.get('code') ||
        searchParams.get('id_token') ||
        searchParams.get('session_state') ||
        searchParams.get('state') ||
        searchParams.get('error') ||
        hashParams.get('code') ||
        hashParams.get('id_token') ||
        hashParams.get('session_state') ||
        hashParams.get('state') ||
        hashParams.get('error'));
};
var initAdapter = function (props) {
    if (props.authAdapter)
        return props.authAdapter;
    return new AuthAdapter(props);
};
export var AuthContext = React.createContext(undefined);
export var AuthProvider = function (_a) {
    var store = _a.store, children = _a.children, props = __rest(_a, ["store", "children"]);
    return (_jsx(Provider, __assign({ store: store }, { children: _jsx(AuthProviderContext, __assign({ store: store }, props, { children: children })) })));
};
var AuthProviderContext = function (_a) {
    var store = _a.store, children = _a.children, _b = _a.autoSignIn, autoSignIn = _b === void 0 ? false : _b, _c = _a.silentSignin, silentSignin = _c === void 0 ? false : _c, onBeforeSignIn = _a.onBeforeSignIn, onSignIn = _a.onSignIn, onSignOut = _a.onSignOut, props = __rest(_a, ["store", "children", "autoSignIn", "silentSignin", "onBeforeSignIn", "onSignIn", "onSignOut"]);
    var dispatch = useDispatch();
    var isLoading = useSelector(selectIsLoading);
    var isAuthed = useSelector(selectIsAuthed);
    var adapter = useState(initAdapter(props))[0];
    var signOutHooks = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            dispatch(logged_out());
            onSignOut && onSignOut();
            return [2];
        });
    }); };
    var signInPopupHooks = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    dispatch(loading());
                    return [4, adapter.signInPopup({})];
                case 1:
                    _a.sent();
                    onSignIn && onSignIn();
                    return [2];
            }
        });
    }); };
    var isMountedRef = useRef(true);
    useEffect(function () {
        return function () {
            isMountedRef.current = false;
        };
    }, []);
    var init = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!isAuthed && autoSignIn) {
                onBeforeSignIn && onBeforeSignIn();
            }
            else if (isMountedRef.current) {
                onSignIn && onSignIn();
                dispatch(loaded());
            }
            return [2];
        });
    }); }, [
        location,
        adapter,
        dispatch,
        isAuthed,
        autoSignIn,
        onBeforeSignIn,
        onSignIn,
    ]);
    useEffect(function () {
        init();
    }, [init]);
    useEffect(function () {
        if (silentSignin === true &&
            window.frameElement === null &&
            !hasCodeInUrl(location)) {
            dispatch(loading());
            adapter
                .fetchServiceConfiguration()
                .then(function () { return __awaiter(void 0, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0: return [4, adapter.signInSilent({})];
                        case 1:
                            _a.sent();
                            dispatch(loaded());
                            onSignIn && onSignIn();
                            return [2];
                    }
                });
            }); })
                .catch(function (e) {
                dispatch(loaded());
                if (e.message === 'error_oidc_configuration') {
                    dispatch(auth_error({
                        name: 'error_oidc_configuration',
                        message: 'Incorrect OIDC configuration',
                    }));
                }
                if (e.error !== 'login_required') {
                    console.error(e);
                }
            });
        }
    }, []);
    var signInCallback = function () {
        return new Promise(function (resolve, reject) {
            if (hasCodeInUrl(location)) {
                var timeout_1 = setTimeout(function () {
                    reject('waitForLogin timeout');
                }, 2000);
                adapter
                    .fetchServiceConfiguration()
                    .then(function () {
                    adapter.completeAuthorizationRequestIfPossible().then(function () {
                        clearTimeout(timeout_1);
                        resolve();
                    });
                })
                    .catch(function (e) {
                    clearTimeout(timeout_1);
                    reject('Login failed: ' + e);
                });
            }
            else {
                reject('No login flow present');
            }
        });
    };
    var registerHandler = useCallback(function () { return __awaiter(void 0, void 0, void 0, function () {
        var updateState;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    updateState = function (type, token_response) { return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (type) {
                                case EventType.RENEWED:
                                    isMountedRef.current &&
                                        token_response
                                            .toReduxState()
                                            .then(function (value) { return dispatch(token_updated(value)); });
                                    break;
                                case EventType.REFRESH_ERROR:
                                    dispatch(auth_error({
                                        name: "error_session_expired",
                                        message: "Session Expired",
                                    }));
                                    break;
                                case EventType.EXPIRED:
                                    isMountedRef.current && dispatch(expired());
                            }
                            return [2];
                        });
                    }); };
                    return [4, adapter.fetchServiceConfiguration()];
                case 1:
                    _a.sent();
                    adapter.addHandler(updateState);
                    return [2, function () { return adapter.removeHandler(updateState); }];
            }
        });
    }); }, [adapter, dispatch]);
    useEffect(function () {
        registerHandler();
    }, [registerHandler]);
    return (_jsx(Provider, __assign({ store: store }, { children: _jsx(AuthContext.Provider, __assign({ value: {
                signinCallback: signInCallback,
                signIn: function (args) {
                    if (args === void 0) { args = {}; }
                    return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4, adapter.maybeFetchServiceConfiguration()];
                                case 1:
                                    _a.sent();
                                    return [2, adapter.signInRedirect(args)];
                            }
                        });
                    });
                },
                signInPopup: function () { return __awaiter(void 0, void 0, void 0, function () {
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4, signInPopupHooks()];
                            case 1:
                                _a.sent();
                                return [2];
                        }
                    });
                }); },
                signOut: function (args) {
                    if (args === void 0) { args = {}; }
                    return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4, adapter.maybeFetchServiceConfiguration()];
                                case 1:
                                    _a.sent();
                                    adapter.signOut(args);
                                    return [4, signOutHooks()];
                                case 2:
                                    _a.sent();
                                    return [2];
                            }
                        });
                    });
                },
                signOutRedirect: function (args) {
                    if (args === void 0) { args = {}; }
                    return __awaiter(void 0, void 0, void 0, function () {
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4, adapter
                                        .maybeFetchServiceConfiguration()
                                        .then(function () { return adapter.signOut(args); })];
                                case 1:
                                    _a.sent();
                                    return [4, signOutHooks()];
                                case 2:
                                    _a.sent();
                                    return [2];
                            }
                        });
                    });
                },
                isLoading: isLoading,
            } }, { children: children })) })));
};
