import { DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS, } from '.';
var IFrameWindow = (function () {
    function IFrameWindow(params) {
        var _this = this;
        this._resolve = function () { };
        this._reject = function () { };
        this._promise = new Promise(function (resolve, reject) {
            _this._resolve = resolve;
            _this._reject = reject;
        });
        this._iframe = window.document.createElement('iframe');
        this._iframe.style.visibility = 'hidden';
        this._iframe.style.position = 'absolute';
        this._iframe.width = '0px';
        this._iframe.height = '0px';
        if (params === null || params === void 0 ? void 0 : params.id) {
            this._iframe.id = params.id;
        }
        window.document.body.appendChild(this._iframe);
    }
    Object.defineProperty(IFrameWindow.prototype, "promise", {
        get: function () {
            return this._promise;
        },
        enumerable: false,
        configurable: true
    });
    IFrameWindow.prototype.navigate = function (params) {
        var _this = this;
        var _a;
        if (!this._iframe) {
            this._reject(new Error('PopupWindow.navigate: Error opening popup window'));
        }
        else if (!params || !params.url) {
            this._reject(new Error('PopupWindow.navigate: no url provided'));
            this._reject(new Error('No url provided'));
        }
        else {
            this._timeoutTimer = window.setTimeout(function () {
                _this.cleanup();
                _this._reject(new Error('IFrame Timeout'));
            }, (params.timeoutInSeconds || DEFAULT_AUTHORIZE_TIMEOUT_IN_SECONDS) * 1000);
            this._iframeEventListener = function (e) {
                if (!e.data || e.data.type !== 'authorization_response') {
                    return;
                }
                _this.cleanup();
                if (e.data.response) {
                    return _this._resolve(e.data.response);
                }
                if (e.data.error) {
                    return _this._reject(e.data.error);
                }
                return _this._reject(new Error('OIDC: Both response and error where empty'));
            };
            window.addEventListener('message', this._iframeEventListener);
            (_a = this._iframe.contentWindow) === null || _a === void 0 ? void 0 : _a.location.replace(params.url);
        }
        return this.promise;
    };
    IFrameWindow.prototype.cleanup = function () {
        if (this._timeoutTimer !== null) {
            window.clearTimeout(this._timeoutTimer);
        }
        this._timeoutTimer = undefined;
        if (this._iframeEventListener) {
            window.removeEventListener('message', this._iframeEventListener, false);
        }
        if (this._iframe) {
            window.document.body.removeChild(this._iframe);
        }
        this._iframe = null;
    };
    IFrameWindow.prototype.abort = function () {
        if (this._timeoutTimer !== null) {
            window.clearTimeout(this._timeoutTimer);
        }
        this._timeoutTimer = undefined;
        if (this._iframeEventListener) {
            window.removeEventListener('message', this._iframeEventListener, false);
        }
        if (this._iframe) {
            window.document.body.removeChild(this._iframe);
        }
        this._iframe = null;
    };
    IFrameWindow.notifyOpener = function (request, response, error) {
        if (window.parent && window != window.parent) {
            window.parent.postMessage({
                type: 'authorization_response',
                request: request,
                response: response,
                error: error,
            }, window.location.origin);
        }
    };
    return IFrameWindow;
}());
export { IFrameWindow };
